import Vue from 'vue'
import VueRouter from 'vue-router'

import Nexus from '@/views/Nexus'
import Snitches from '@/views/Snitches'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Snitches',
    component: Snitches
  },
  {
    path: '/nexus',
    name: 'Nexus',
    component: Nexus
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
