<template>
  <div class="p-5 text-xs font-mono select-none min-w-max max-w-max mx-auto">
    <h1
      class="text-2xl text-center mb-5"
      @mouseover="progradeEastward = true"
      @mouseout="progradeEastward = false"
    >
        {{ ['🌏','🌍','🌎'][progradeIndex] }} Sensoreal Snitch
      </h1>
    <table class="table-auto text-center">
      <tr>
        <th class="px-2">Device</th>
        <th class="px-2">Last Device Seen</th>
        <th class="px-2">Last Sensoreal Seen</th>
        <th class="px-2">Last Sync Attempt</th>
        <th class="px-2">Last Sync Success</th>
        <th class="px-2">Last Boot</th>
      </tr>
      <tr v-for="device in keys(sortedSnitches)" class="bg-gray-200 odd:bg-gray-100" :key="device">
        <td class="px-2 text-left select-text">{{ device }}</td>
        <td class="px-2 text-left"><span class="mr-2 emoji">{{ getSeenStatus(sortedSnitches[device].last_seen) }}</span>{{ parseTimestamp(sortedSnitches[device].last_seen) || '-' }}</td>
        <td class="px-2 text-left"><span class="mr-2 emoji">{{ getSyncAttemptStatus(sortedSnitches[device].touch_seen) }}</span>{{ parseTimestamp(sortedSnitches[device].touch_seen) || '-' }}</td>
        <td class="px-2 text-left"><span class="mr-2 emoji">{{ getSyncAttemptStatus(sortedSnitches[device].last_sync_attempt) }}</span>{{ parseTimestamp(sortedSnitches[device].last_sync_attempt) || '-' }}</td>
        <td class="px-2 text-left"><span class="mr-2 emoji">{{ getSyncSuccessStatus(sortedSnitches[device].last_sync_success) }}</span>{{ parseTimestamp(sortedSnitches[device].last_sync_success) || '-' }}</td>
        <td class="px-2 text-left"><span class="mr-2 emoji">{{ getBootStatus(sortedSnitches[device].last_boot) }}</span>{{ parseTimestamp(sortedSnitches[device].last_boot) || '-' }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data () {
    return {
      progradeEastward: false,
      progradeIndex: 0,
      snitches: {}
    }
  },
  computed: {
    sortedSnitches () {
      let normalizedSnitches = {}
      Object.keys(this.snitches).forEach(snitch => {
        const normalizedSnitch = snitch.toLowerCase().replace('.local', '').trim()
        normalizedSnitches[normalizedSnitch] = normalizedSnitches[normalizedSnitch] || {}
        Object.assign(normalizedSnitches[normalizedSnitch], this.snitches[snitch])
      })
      console.log(normalizedSnitches)
      return Object.keys(normalizedSnitches).sort().reduce((r, k) => (r[k] = normalizedSnitches[k], r), {})
    },
  },

  mounted () {
    this.getSnitches()

    setInterval(() => {
      this.getSnitches()
    }, 5000)

    setInterval(() => {
      if (this.progradeEastward) {
        this.progradeIndex++
        if (this.progradeIndex > 2) {
          this.progradeIndex = 0
        }
      }
    }, 200)
  },

  methods: {
    keys (object) {
      return Object.keys(object)
    },

    getSnitches () {
      axios.get(process.env.VUE_APP_SNITCH_SERVER ? `${process.env.VUE_APP_SNITCH_SERVER}/api/snitches` : '/api/snitches').then(res => {
        this.snitches = res.data
      })
    },

    getSeenStatus (ms) {
      if (ms) {
        const now = new Date().getTime()
        const difference = now - ms
        const minutes = Math.round(difference / 1000 / 60)
        return minutes > 60 * 24 ? '🚨' : minutes > 1 ? '⚠️' : '✅'
      } else {
        return ''
      }
    },

    getSyncAttemptStatus (ms) {
      if (ms) {
        const now = new Date().getTime()
        const difference = now - ms
        const minutes = Math.round(difference / 1000 / 60)
        return minutes > 10 ? '🚨' : minutes > 5 ? '⚠️' : '✅'
      } else {
        return ''
      }
    },    

    getSyncSuccessStatus (ms) {
      if (ms) {
        const now = new Date().getTime()
        const difference = now - ms
        const minutes = Math.round(difference / 1000 / 60)
        return minutes > 60 * 24 * 14 ? '🚨' : minutes >  60 * 24 * 7 ? '⚠️' : '✅'
      } else {
        return ''
      }
    },

    getBootStatus (ms) {
      if (ms) {
        const now = new Date().getTime()
        const difference = now - ms
        const minutes = Math.round(difference / 1000 / 60)
        return minutes > 60 * 24 * 1 ? '🚨' : '✅'
      } else {
        return ''
      }
    },    

    parseTimestamp (ms) {
      const now = new Date().getTime()
      const difference = now - ms
      const minutes = Math.round(difference / 1000 / 60)
      if (minutes < 1) {
        return '< 1m ago'
      }
      if (minutes < 60) {
        return minutes + 'm ago'
      }
      if (minutes >= 60 && minutes < 1440) {
        return Math.floor(minutes / 60) + 'hr ' + minutes % 60 + 'm ago'
      }
      if (minutes >= 1440) {
        return Math.floor(minutes / 1440) + 'd ' + Math.floor(minutes % 1440 / 60) + 'hr ' + minutes % 60 + 'm ago'
      }      
    },
  }
}
</script>
<style>
.emoji {
  font-family: 'emoji';
}
</style>