export default {
  apiServers: [
    { name: 'waterford',
      host: 'https://api.waterford.sensoreal.com.au'
    },
    { name: 'carolina',
      host: 'https://api.carolina.sensoreal.com.au'
    },
    { name: 'covella',
      host: 'https://api.covella.sensoreal.com.au'
    },
    { name: 'bloomdale',
      host: 'https://api.bloomdale.sensoreal.com.au'
    },
    { name: 'harmony',
      host: 'https://api.harmony.sensoreal.com.au'
    },
    { name: 'savana',
      host: 'https://api.savana.sensoreal.com.au'
    },
    { name: 'skyridge',
      host: 'https://api.skyridge.sensoreal.com.au'
    },
    { name: 'brentwood forest',
      host: 'https://api.brentwoodforest.sensoreal.com.au'
    },
    { name: 'riverlea',
      host: 'https://api.riverlea.sensoreal.com.au'
    },
    { name: 'amara',
      host: 'https://api.amara.sensoreal.com.au'
    },
    { name: 'project vista',
      host: 'https://api.projectvista.sensoreal.com.au'
    },
    { name: 'springwood',
      host: 'https://api.springwood.sensoreal.com.au'
    },
    { name: 'maroochydore',
      host: 'https://api.maroochydore.sensoreal.com.au'
    },
    { name: 'silver to sea',
      host: 'https://api.silvertosea.sensoreal.com.au'
    },
    { name: 'chambers reserve',
      host: 'https://api.chambersreserve.sensoreal.com.au'
    },
    { name: 'vantage bloomdale',
      host: 'https://api.vantagebloomdale.sensoreal.com.au'
    },
    { name: 'vantage harmony',
      host: 'https://api.vantageharmony.sensoreal.com.au'
    },
    { name: 'elan',
      host: 'https://api.elan.sensoreal.com.au'
    },
    { name: 'hervey bay',
      host: 'https://api.herveybay.sensoreal.com.au'
    },
    { name: 'st james',
      host: 'https://api.stjames.sensoreal.com.au'
    },
  ]
}
